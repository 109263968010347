<template>
    <div class="membre_declaration">
        <!--<h3>Nom du fichier PDF:</h3>
        <div class="vx-col w-full">
            <vs-input class="w-full" v-model="name" />
        </div>
        <br/><br/>-->

        <!--<vs-divider/>-->
        <!--<h3>Contenu:</h3>-->
        <ckeditor :editor="editor" v-model="editorData" :config="editorConfig" ></ckeditor>
        <br/>
        <br/>
        <vs-checkbox color="success" v-model="valide">j'ai pris connaissances des contre-indications</vs-checkbox>
        <br/>
        <br/>

        <vx-card class="w-full">
            <vs-button color="primary" type="border" class="float-right" v-on:click="SignClear()">Nettoyer</vs-button>
            <h3>Signature:</h3>
            <vs-divider/>
            <VueSignaturePad
                id="signature"
                width="100%"
                height="300px"
                ref="signaturePad"
            />
        </vx-card>
        <br/>
        <br/>
        
        <vs-row>
            <vs-col vs-type="flex" vs-justify="space-between" vs-align="center" vs-xs="12">
                <span></span>
                <vs-button color="success" type="filled" v-on:click="create()" >Valider</vs-button>
            </vs-col>
        </vs-row>
    </div>
</template>



<style lang="scss">
</style>

<script>

import autoRefresh from '@/assets/utils/autoRefresh'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import Member  from '@/database/models/member';

export default {
    data(){
        return{
            editor: ClassicEditor,
            editorData: "",
            editorConfig: {
                // The configuration of the editor.
            },

            name:'declaration',
            valide:false,
        }
	},
	mounted:function()
	{
        //autoRefresh
		autoRefresh.SetCallback(()=>{
			//refresh
            this.Load()
        })
        autoRefresh.refresh()
    },
    watch:{
        $route (){
            
        }
    },
    methods:{
		SignClear()
        {
            this.$refs.signaturePad.clearSignature()
        },

        Load()
        {
            let uid_member = this.$route.params.uid;
            Member.getMemory( uid_member, async (member) => {
                this.$srvApi.Req('get', 'studio/declaration')
                .then(( rep )=>
                {
                    this.editorData = rep.data;
                    
                    const date = new Date();
                    let currentDay= String(date.getDate()).padStart(2, '0');
                    let currentMonth = String(date.getMonth()+1).padStart(2,"0");
                    let currentYear = date.getFullYear();

                    this.editorData += `<br/>&nbsp;<br/>Je, soussigné <b>${member.first_name.charAt(0).toUpperCase() + member.first_name.slice(1)}</b> <b>${member.last_name.charAt(0).toUpperCase() + member.last_name.slice(1)}</b>, le ${currentDay}/${currentMonth}/${currentYear}.`
                });
            })
        },

        create()
        {
            //if(this.name.length < 10 )
            //    return alert('Nom trop court !')
            
            let html = this.editorData;
            if(html.length< 10 )
                return alert('Contenu trop court !');

            if(!this.valide)
                return alert('veuillez cocher la case !');

            if(this.$refs.signaturePad.isEmpty() == true)
                return alert('Aucune signature !')


            let signature = this.$refs.signaturePad.saveSignature().data
            html +='<br/><br/><img src="'+signature+'" width="300">'
            
            //Envoi des data pour création
            this.$srvApi.Req('post', 'user/declaration/'+this.$route.params.uid,{
                uid  : this.$route.params.uid,
                name : this.name,
                html : escape(html),
            })
            .then(( rep )=>
            {
                //console.log( rep )

                //err ?
                if(rep.status != 'success')
                    return alert("Impossible de créer la déclaration !");
                alert("Déclaration créée.");


                //Retour planning
                //this.$router.push('/admin/booking/');
                this.$router.go(-1);//Retour page arriére
            })


        }
    }
};

</script>